import { createRoot } from 'react-dom/client'
import 'whatwg-fetch' // cypress fetch fix

import App from 'App'
import { userManager, oidcConfig } from 'keycloak/config/oidcConfig';
import { AuthProvider } from 'react-oidc-context'

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <AuthProvider userManager={userManager} {...oidcConfig}>
    <App />
  </AuthProvider>
)
