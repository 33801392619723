// @flow

import { useEffect, memo, useState, useRef } from 'react'
import { Spinner } from 'react-bootstrap'
import { PaginateIt, CommonTable, Dialog, Row, Col, PageTitle, Text, Gap, Container } from 'components/ReUsable'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useReactTable, getCoreRowModel } from '@tanstack/react-table'
import { useMediaQuery } from '@mui/material'

import * as C from 'appConstants'

import { getDevices, togglePageDialog, clearDevicesPageDialog, toggleSelectionMode, updateDeviceSorting, updateDevicePageNumber } from 'actions'

import { defineColumns } from './defineColumns'
import SearchForm from './searchForm'
import DeviceClaimingContent from './deviceClaimingContent'
import { useHistory } from 'react-router'
import { integrationTestRunning } from 'utils/helpers'
import { defaultDeviceColumns } from 'reducers/devicesReducer'
import usePageVisibility from '../Device/hooks/usePageVisibility'

const homeState = (state) => ({
  devices: state?.devices,
  dialog: state?.dialog
})

export const Home = () => {
  const isDesktop = useMediaQuery('(min-width:992px)')
  const [isPageVisible, updateDependencies] = usePageVisibility()
  const initialRender = useRef(true)
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)
  const { devices, dialog } = useSelector(homeState, shallowEqual)
  const { isDialogOpen, isPageDialogOpen, dialogId } = dialog
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const _getDevices = (payload) => dispatch(getDevices(payload))
  const _togglePageDialog = () => dispatch(togglePageDialog())
  const _clearDevicesPageDialog = () => dispatch(clearDevicesPageDialog())
  const _toggleSelectionMode = (payload) => dispatch(toggleSelectionMode(payload))
  const _updateDeviceSorting = (payload) => dispatch(updateDeviceSorting(payload))
  const _updateDevicePageNumber = (payload: number) => dispatch(updateDevicePageNumber(payload))

  const history = useHistory()
  const callGetDevicesRequest = (resetPage?: boolean) => {
    setLoading(true) // Show spinner when fetching starts
    Promise.resolve(
      _getDevices({
        page: resetPage ? 1 : devices.page,
        searchValue: devices.search.searchValue,
        sorting: devices.sorting,
        filter: devices.filter
      })
    ).finally(() => setLoading(false)) // Hide spinner when fetching ends
  }

  // Polling depending on device detail page visibility + tab changes.
  useEffect(() => {
    if (isPageVisible && updateDependencies) {
      callGetDevicesRequest()

      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
      intervalRef.current = setInterval(() => callGetDevicesRequest(), C.POLLING_MS_INTERVAL)
    }

    deactivateSelectionMode()

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [isPageVisible, updateDependencies])

  const deactivateSelectionMode = () => {
    //  Disable selection mode, if user is NOT coming back from the devices/delegations (BatchDelegation) page. Or from the devices/licenses (ManageLicenses) page.
    const referrer = (history?.location?.state as any)?.from || ''
    if (referrer.indexOf('/devices/delegations/') === -1 && referrer.indexOf('/devices/licenses/') === -1) {
      _toggleSelectionMode({ selectionActive: false })
    }
  }

  // This useEffect is called when the user adds an efoy Device
  useEffect(() => {
    if (devices.efoyAddedSuccess) {
      callGetDevicesRequest()
    }
  }, [devices.efoyAddedSuccess])

  useEffect(() => {
    // This useEffect is called when the user changes filters or sorting or search
    // this won't run on component initial load
    if (initialRender.current) return
    callGetDevicesRequest(!initialRender.current)
    // We create a new polling with current changes of filter instead of old one so that when the changes of the filter wont be overriden by the polling made on the first render or previous polling
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = setInterval(() => {
        callGetDevicesRequest()
      }, C.POLLING_MS_INTERVAL)
    }
  }, [
    devices?.filter?.name,
    devices?.filter?.serialNumber,
    devices?.filter?.compoundState,
    devices?.filter?.firmwareVersion,
    devices?.search?.searchValue,
    devices?.filter,
    devices?.sorting?.sortKey,
    devices?.sorting?.sortDirection
  ])

  //  Called if current page of devices table has been changed.
  //  This call should not happen on component initial load.
  //  Does not reset any filter.
  useEffect(() => {
    if (initialRender.current) return
    callGetDevicesRequest()
    // We create a new polling with current device page instead of old one so that the current page wont be overriden by the polling made on the first render or previous polling
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = setInterval(() => {
        callGetDevicesRequest()
      }, C.POLLING_MS_INTERVAL)
    }
  }, [devices.page])

  // Initial render.
  useEffect(() => {
    if (initialRender.current) {
      // updating initial render to false
      initialRender.current = false
      // call initial get devices request
      callGetDevicesRequest()
      // clear interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
      intervalRef.current = setInterval(() => callGetDevicesRequest(), C.POLLING_MS_INTERVAL)
    }

    return () => {
      // clear interval on dismount
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  // eslint-disable-next-line react/display-name
  const MemoizedSpinner = memo(() => <Spinner animation="border" variant="secondary" />)

  const table = useReactTable({
    data: devices.items,
    columns: defineColumns(devices, _updateDeviceSorting, isDesktop ? devices.selectedColumns : defaultDeviceColumns),
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <main role="main" className={`${isDialogOpen ? 'blur-element' : ''}`}>
      <Container data-cy="page-home">
        <Row margin="1rem 0">
          <Col>
            <PageTitle data-cy="page-home-title">EFOY Fuel Cells</PageTitle>
            <Text className="d-none d-lg-table-cell">
              All EFOY Fuel Cells currently claimed to your account are displayed here. You can organize your overview, export it or claim further EFOY Fuel Cells.
            </Text>
          </Col>
          <Gap></Gap>
          <Col alignItems="flex-end">
            <SearchForm devices={devices} formInitialValues={devices.search} sorting={devices.sorting} />
          </Col>
        </Row>
        {devices.loaded === false && <MemoizedSpinner />}

        {devices.loaded && devices.items.length >= 0 && (
          <PaginateIt
            page={devices.page}
            totalPages={devices.totalPages}
            firstPage={devices.firstPage}
            lastPage={devices.lastPage}
            jumpToPage={(pageNumber: number) => {
              _updateDevicePageNumber(pageNumber)
            }}
            showLegend={true}
            selectionModeSupported={true}
            loading={loading}
            columnSettingsVisible={true}
          >
            <CommonTable tableClass={'table table-bordered'} theadLineClass={'table-dark-grey'} table={table} data-cy="common-table" hasFilters={!!devices.filter} />
          </PaginateIt>
        )}
      </Container>

      <Dialog
        id="device-claiming-dialog"
        className="modal-page"
        title={devices.pageDialog && devices.pageDialog.title}
        show={isPageDialogOpen && dialogId === 'device-claiming-dialog'}
        onClose={() => {
          _togglePageDialog()
          _clearDevicesPageDialog()
        }}
      >
        <DeviceClaimingContent />
      </Dialog>
    </main>
  )
}

export default Home
