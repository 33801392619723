import { LAST_CALLED_URL } from 'components/Application/PrivateRoute/authGuard'
import { User, WebStorageStateStore, UserManager } from 'oidc-client-ts'

const auth0Domain = (window as any).REACT_APP_AUTH0_DOMAIN ? (window as any).REACT_APP_AUTH0_DOMAIN : process.env.REACT_APP_AUTH0_DOMAIN
const clientId = (window as any).REACT_APP_AUTH0_CLIENT_ID ? (window as any).REACT_APP_AUTH0_CLIENT_ID : process.env.REACT_APP_AUTH0_CLIENT_ID

//  Passed to the OidcProvider to handle authentication automatically.
const oidcConfig = {
  authority: auth0Domain,
  client_id: clientId,
  redirect_uri: window.location.origin, // Redirect URI after login
  silent_redirect_uri: `${window.location.origin}/silent-renew.html`, // URI for silent renewal
  response_type: 'code',
  scope: 'openid profile email',
  post_logout_redirect_uri: `${window.location.origin}/home.html`,
  automaticSilentRenew: true, // Enables automatic silent token renewal
  loadUserInfo: true, // Loads user info when the token is renewed
  onSigninCallback: (_user: User | void): void => {
    //  Get last called page before signin, without authentication. To redirect the user
    const lastCalledUrl = sessionStorage.getItem(LAST_CALLED_URL)
    if (lastCalledUrl) {
      sessionStorage.removeItem(LAST_CALLED_URL)
      window.location.replace(lastCalledUrl)
    } else {
      // You must provide an implementation of onSigninCallback to oidcConfig to remove the payload
      // from the URL upon successful login.
      // Otherwise if you refresh the page and the payload is still there, signinSilent - which handles renewing your token - won't work.
      window.history.replaceState({}, document.title, window.location.pathname)
    }
  },
  userStore: new WebStorageStateStore({ store: window.sessionStorage }) //  Use localStorage to store the user
}

// Create a singleton UserManager instance
const userManager = new UserManager(oidcConfig);

export { userManager, oidcConfig };
